
import { Component, Vue } from 'vue-property-decorator'
import { wareHousingList } from '@/types/assetScheduling'
import Examine from '@/views/assetScheduling/transfer/Examine.vue'

@Component({
  name: 'scheduling',
  components: { Examine }
})
export default class BraceletList extends Vue {
  private tableData: wareHousingList[] =[]
  private info= {
    orderNumber: '',
    startDate: '',
    endDate: '',
    transferSumState: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private dataRange = []
  private assetStateList = ['进行中', '已结束', '已打回']
  private types = ['待调入方审批', '待调出方审批', '结束', '打回']
  private showDialog = false
  private id = ''
  get useId () {
    const userInfo = window.sessionStorage.getItem('userInfo') || ''
    return userInfo ? JSON.parse(userInfo).userId : ''
  }

  created () {
    this.getData()
  }

  getData () {
    if (this.dataRange) {
      this.info.startDate = this.dataRange[0]
      this.info.endDate = this.dataRange[1]
    } else {
      this.info.startDate = ''
      this.info.endDate = ''
    }
    this.loading = true
    this.$axios.get(this.$apis.assetScheduling.selectAssetAllocateByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.$router.push({ path: '/transfer/add' })
  }

  // 审批
  onExamine (orderId: string) {
    this.showDialog = true
    this.id = orderId
  }

  success () {
    this.id = ''
    this.getData()
  }

  onDelete (orderId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.assetScheduling.deleteAssetAllocate, { orderId: orderId }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      }).finally(() => {
        this.loading = false
      })
    })
  }

  onDetail (orderId: string) {
    this.$router.push({ name: 'transferDetail', params: { orderId: orderId } })
  }

  isShowExamine (row: any) {
    return (row.transferState === '1' && this.useId === row.putUserId) || (row.transferState === '2' && this.useId === row.outUserId)
  }

  // 导出
  onExport (id: string) {
    const loading = this.$loading({
      lock: true,
      text: '导出中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.assetScheduling.exportAssetAllocate, { orderId: id }).finally(() => {
      loading.close()
    })
  }
}
